import React, { useState, useEffect } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import LoginHeader from "../login/LoginHeader";
import AccountHeader from "../account-header/AccountHeader";
import "../how-to-play/HowToPlay.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import useAlert from "../../hooks/UseAlert";
import Parser from "html-react-parser";

export default function HowToPlay() {
  const [loggedStatus, setLoggedStatus] = useState("");
  const [userDataUserId, setUserId] = useState();
  var url = process.env.REACT_APP_API_URL;
  const [howToPlayContent, setHowToPlayContent] = useState("");
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }
    if (loggedUserData !== null) {
      setUserId(loggedUserData);
    }

    var challange_url = "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "how_to_play" };
    axios
      .post(challange_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setHowToPlayContent(page_content);
        }
      })
      .catch((err) => {
        alert_notify("error", err.response.data.Error);
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | How to Play</title>
        <meta
          name="description"
          content="4 easy steps to play Top Predictor. Learn how to make predictions for Football, NFL, NHL Cricket and more. "
        />
      </Helmet>
      {!userDataUserId ? <LoginHeader howToPlayValue={true} /> : <Header />}
      <AccountHeader
        headerValue="HOW TO PLAY"
        firstTextValue="4 Easy-to-follow steps"
      />
      <div className="how_to_play_container m-0 col-md-12 primary-background">
        <div className="page_content_center">
          {Parser(howToPlayContent)}
          {/* <span className="how_to_play_step">STEP 1</span>
          <ul className="mt-3">
            <li>
              Select a sport, the league or event from the View Sports, or
              simply click on a banner on the landing.
            </li>
            <li>
              This will navigate you to where you select your entry fee and the
              number of entries you compete against. Submitting will display
              prize fund and time left before competition starts.
            </li>
            <li>
              Selecting 'Play Now' will direct you to the gamecard where you
              make your predictions match by match or all at once. Predictions
              need to be made before match starts.
            </li>
            <li>
              Make your predictions by clicking on an option, you can change
              your selection by clicking on an alternative option. Once you have
              submitted a prediction that match will disappear from the
              gamecard. As you make your predictions the “Match Predictions”
              boxes at the top of the gamecard change colour to show the state
              of each match. Clicking on the numbered boxes reinstates matches
              into your gamecard
            </li>
            <iframe
              className="how_to_play_video"
              alt="video-how-to-play"
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/oKQ1Z1MspxI"
              title="HowToPlayVideo"
            ></iframe>
          </ul>
          <span className="how_to_play_step">STEP 2</span>
          <ul className="mt-3">
            <li>
              The information button (i) alongside each team opens a new window
              displaying, matchday squad, Teams last 5 results, H2H, Match News,
              injuries etc. This may help make predictions.
            </li>
            <iframe
              alt="video-how-to-play"
              className="how_to_play_video"
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/v5tPDEH7kfg"
              title="HowToPlayVideo"
            ></iframe>
          </ul>
          <span className="how_to_play_step">STEP 3</span>
          <ul className="mt-3">
            <li>
              Wildcards enhances the excitement! You have a Joker and an Ace for
              each competition. Playing your Joker on an option triples the
              points, the Ace doubles the points. Placed correctly boosts your
              chance of winning. As with all your predictions, wildcard
              decisions can be changed before kick-off.
            </li>
            <iframe
              alt="video-how-to-play"
              className="how_to_play_video"
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/JtNPr2ofeb4"
              title="HowToPlayVideo"
            ></iframe>
          </ul>
          <span className="how_to_play_step">STEP 4</span>
          <ul className="mt-3">
            <li>
              You can check your position on the leader board, which can be
              accessed via the gamecard or My Competitions, “Live” .
            </li>
            <li>
              If you prefer to play in a private competition with friends, click
              on “Click Here” on “Create a Private Competition” or “Challenge”
              and follow instructions.
            </li>
            <li>
              We offer Free to Play competitions however, limited to five
              competitions
            </li>
            <iframe
              alt="video-how-to-play"
              className="how_to_play_video"
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/HVh_W1EHTd8"
              title="HowToPlayVideo"
            ></iframe>
          </ul> */}
        </div>
      </div>
      {alert}
      <Footer />
    </React.Fragment>
  );
}
