import React, { useState, useEffect } from "react";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../sports/Challange.css";
import axios from "axios";
import UseLoading from "../../../hooks/UseLoading";
import UseAlert from "../../../hooks/UseAlert";
import moment from "moment";

export default function Challange() {
  const [searchParams] = useSearchParams();
  const [selectedLeague, setSelectedLeague] = useState("");
  const [sessionKey, setSessionKey] = useState("");
  const [reload, setReload] = useState(true);
  const [loader, showLoader, hideLoader] = UseLoading();
  const [competitions, setCompetitions] = useState([]);
  const [alert, alert_notify] = UseAlert();
  const { search, pathname } = useLocation();
  const { leagueId, sportId, contestId } = useParams();
  if (sportId == 13) {
    var challenges_titles = [
      "Sport",
      "Tournament",
      "Entries",
      "Start Date",
      "End Date",
      "Prize Pool",
      "Entry Fee",
    ];
  } else {
    var challenges_titles = [
      "Sport",
      "League",
      "Entries",
      "Start Date",
      "End Date",
      "Prize Pool",
      "Entry Fee",
    ];
  }

  const navigate = useNavigate();
  var url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    console.log(search);
    const match = search.match(/leauge_id=(.*)/);
    const league_id = match?.[1];
    var session_key = localStorage.getItem("session_key");
    var content_url = `${url}/api/lobby/fetch_contest`;
    setSessionKey(session_key);

    const body = {
      league_id: leagueId,
      sport_id: sportId,
      contest_id: contestId,
    };
    const headers = { session_key: session_key };
    showLoader();
    axios
      .post(content_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setCompetitions(response.data.Data.contest);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          sessionStorage.setItem("back_url", window.location.pathname);
          if (searchParams.get("affiliate")) {
            localStorage.setItem("affiliateCompetition", pathname);
            navigate(`/register?affiliate=${searchParams.get("affiliate")}`);
          } else {
            navigate("/login");
          }
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, [leagueId, reload]);

  var i = 0;
  var challange_row;
  var public_icon;
  var selected_icon;

  // Join Contest
  const joinContest = (e) => {
    var element = document.querySelector(".selected_contest");
    if (element === null) {
      return false;
    }
    var contest_id = element.getAttribute("data-contestid");
    var league_id = element.getAttribute("data-leagueid");
    var contest_size = element.getAttribute("data-contestsize");
    var entry_fee = element.getAttribute("data-entryfee");
    var contest_uid = element.getAttribute("data-contestuid");
    var sport_id = element.getAttribute("data-sportid");

    var join_contest_url = `${url}/api/lobby/join_contest`;
    const body = {
      contest_id: contest_id,
      league_id: league_id,
      entry_fee: entry_fee,
      contest_size: contest_size,
    };

    const headers = { session_key: sessionKey };

    showLoader();
    axios
      .post(join_contest_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200 && response.data.ResponseCode == 200) {
          var data = response.data.Data;
          alert_notify("success", data.message);
          setTimeout(() => {
            if (sport_id != 13) {
              navigate("/gamecard/" + sport_id + "/" + contest_uid);
            } else {
              navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
            }
          }, 1500);
        }
      })
      .catch((err) => {
        console.log({ err });
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          if (
            err.response.data.Message.includes(
              "You have already submitted the maximum number of entries allowed"
            )
          ) {
            alert_notify("success", "returning to competition now");

            setTimeout(() => {
              if (sport_id != 13) {
                navigate("/gamecard/" + sport_id + "/" + contest_uid);
              } else {
                navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
              }
            }, 2000);
          } else {
            alert_notify("error", err.response.data.Message);
            setTimeout(()=>{
              setReload(!reload);
            }, 1500)
          }
        }
        hideLoader();
      });
  };

  const handleSelectLeague = (e) => {
    var row_index = e.target
      .closest(".challenge.transaction")
      .getAttribute("row-index");
    if (row_index === selectedLeague) {
      setSelectedLeague("");
    } else {
      setSelectedLeague(row_index);
    }
  };

  var challange_rows = document.querySelectorAll(".challenge.row");
  var public_row_icons = document.querySelectorAll(".public_icon.row_icon");
  var selected_row_icons = document.querySelectorAll(
    ".selected_league_icon.row_icon"
  );
  if (selectedLeague !== "") {
    for (i = 0; i < challange_rows.length; i++) {
      challange_row = challange_rows[i];
      public_icon = public_row_icons[i];
      selected_icon = selected_row_icons[i];
      challange_row.classList.remove("active", "selected_contest");
      public_icon.innerHTML =
        '<img src="/assets/icons/public.svg" alt="public-icon"/>';
      selected_icon.innerHTML =
        '<img src="/assets/icons/unselect.svg" alt="unselect-icon"/>';
    }
    document
      .getElementById("challange_row_" + selectedLeague)
      .classList.add("active", "selected_contest");
    document.getElementById("public_icon_" + selectedLeague).innerHTML =
      '<img src="/assets/icons/public_light.svg" alt="public-light-icon"/>';
    document.getElementById(
      "selected_league_icon_" + selectedLeague
    ).innerHTML = '<img src="/assets/icons/select.svg" alt="select-icon"/>';
  } else {
    for (i = 0; i < challange_rows.length; i++) {
      challange_row = challange_rows[i];
      public_icon = public_row_icons[i];
      selected_icon = selected_row_icons[i];
      challange_row.classList.remove("active");
      public_icon.innerHTML =
        '<img src="/assets/icons/public.svg" alt="public-icon"/>';
      selected_icon.innerHTML =
        '<img src="/assets/icons/unselect.svg" alt="unselect-icon"/>';
    }
  }
  return (
    <React.Fragment>
      <div className="my_challenge_container">
        <div className="page_center">
          <div className="challenges mt-5">
            <div className="row challenges_icons_row">
              <span className="public_icon text-white">
                <img src="/assets/icons/public.svg" alt="public-icon" />
                Public
              </span>
              <span className="private_icon text-white">
                <img src="/assets/icons/private.svg" alt="public-icon" />
                Private
              </span>
            </div>
            <div
              className="row challenges_titles"
              style={{ borderRadius: "10px" }}
            >
              <div className="challanges_icons_col"></div>
              <div className="col">Sport</div>
              <div className="col">
                {sportId == 13 ? "Tournament" : "League"}
              </div>
              <div className="challanges_entries">Entries</div>
              <div className="col">Start Date</div>
              <div className="col">End Date</div>
              <div className="challanges_price_pool">Prize Pool</div>
              <div className="challanges_entry_fee">Entry Fee</div>
            </div>
            {competitions.length > 0 ? (
              competitions.map((value, index) => {
                var dateTimeFormat = "DD MMM YYYY";
                var startDateTimeUtc = moment.utc(value.start_at);
                var startLocalDate = startDateTimeUtc.local();
                var startGamePlayTime = startLocalDate.format(dateTimeFormat);
                var endDateTimeUtc = moment.utc(value.end_at);
                var endLocalDate = endDateTimeUtc.local();
                var endGamePlayTime = endLocalDate.format(dateTimeFormat);
                if (value.used_adhoc == "0") {
                  var prize_pool = (
                    value.prize_pool -
                    (value.prize_pool * Number(value.site_rake)) / 100
                  ).toFixed(2);
                } else {
                  var prize_pool = value.prize_pool;
                }
                return (
                  <div
                    className="row challenge transaction m-0 mb-2"
                    id={"challange_row_" + index}
                    data-contestid={value.contest_id}
                    data-contestsize={value.size}
                    data-leagueid={value.league_id}
                    data-entryfee={value.entry_fee}
                    data-contestuid={value.contest_uid}
                    data-sportid={value.sport_id}
                    row-index={index}
                    key={index}
                    style={{ borderRadius: "10px" }}
                    onClick={handleSelectLeague}
                  >
                    <div className="challanges_icons_col d-flex">
                      <span
                        className="public_icon row_icon"
                        id={"public_icon_" + index}
                      >
                        <img src="/assets/icons/public.svg" alt="public-icon" />
                      </span>
                      <span
                        className="selected_league_icon row_icon"
                        id={"selected_league_icon_" + index}
                      >
                        <img
                          src="/assets/icons/unselect.svg"
                          alt="unselect-icon"
                        />
                      </span>
                    </div>
                    <div className="col d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[0]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {value.sport_name}
                      </span>
                    </div>
                    <div className="col d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[1]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {value.league_name}
                      </span>
                    </div>
                    <div className="challanges_entries d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[2]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {value.total_user_joined} / {value.size}
                      </span>
                    </div>
                    <div className="col d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[3]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {startGamePlayTime}
                      </span>
                    </div>
                    <div className="col d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[4]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {endGamePlayTime}
                      </span>
                    </div>
                    <div className="challanges_price_pool d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[5]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {prize_pool} EUR
                      </span>
                    </div>
                    <div className="challanges_entry_fee d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[6]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {value.entry_fee} EUR
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <h5 className="no_available_leagues">
                We don't have competitions for this league right now
              </h5>
            )}
          </div>
          <div className="free_to_play_btns">
            {competitions.length > 0 ? (
              <ButtonPrimary
                classValue="btn_primary btn_play_free_challenge"
                textValue="PLAY"
                onClick={joinContest}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {alert}
      {loader}
    </React.Fragment>
  );
}
