import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SportsContainer from "../sports-container/SportsContainer";
import IndexSlider from "../index/IndexSlider";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";
import { UserContext } from "../user-context/UserContext";
import useLoading from "../../hooks/UseLoading";
import Modal from "react-bootstrap/Modal";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import LandingPage from "../landing-page/LandingPage";
import { Carousel } from "react-bootstrap";
import SportsBanner from "./SportsBanner";
import Summary from "../index/Summary";
export default function Home() {
  var url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  const { userData, setUserData } = useContext(UserContext);
  const [loader, showLoader, hideLoader] = useLoading();
  const [secondLandingPageHorizontalData, setSecondLandingPageHorizontalData] =
    useState([]);
  const [secondLandingPageVerticalData, setSecondLandingPageVerticalData] =
    useState([]);
  const handleReminderClose = () => setShowReminder(false);
  const [showReminder, setShowReminder] = useState(false);
  const [landingPageData, setLandingPageData] = useState([]);

  var secondLandingPageHorizontalDataArray = [];
  var secondLandingPageVerticalDataArray = [];

  useEffect(() => {
    secondLandingPage();
    landingPage();
    var session_key = localStorage.getItem("session_key");
    var profile_url = `${url}/api/my_profile/my_profile`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    axios
      .post(profile_url, body, { headers })
      .then((response) => {
        var data = response.data.Data.user_profile;
        if (response.status === 200) {
          var userProfileData = {
            user_id: data.user_id,
            balance: data.balance,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            winning_balance: data.winning_balance,
            kyc_status: data.kyc_status,
            master_country_id: data.master_country_id,
            master_state_id: data.master_state_id,
            phone_no: data.phone_no,
            status: data.status,
            user_name: data.user_name,
            address1: data.address1,
            address2: data.address2,
            zip_code: data.zip_code,
            dob: data.dob,
            city: data.city,
            opt_in_email: data.opt_in_email,
            bank_statement_image: data.bank_statement_image_v2,
            passport_image: data.passport_image_v2,
            utility_bills_image: data.utility_bills_image_v2,
            default_currency: data.default_currency,
            count_notification: data.unread_notification,
            allow_kyc: data.allow_kyc,
            show_kyc_nottification: data.show_kyc_notification,
          };
          setUserData(userProfileData);

          // disable KYC notification on first visit to homepage
          // if(data.kyc_status != 2){
          //   var isSetReminder = localStorage.getItem("showReminder");
          //   if(data.show_kyc_notification == '0'){
          //     setShowReminder(true);
          //     localStorage.setItem("showReminder", true);
          //     updateShowKYCNotiffication();
          //   }
          // }
          localStorage.setItem("userData", JSON.stringify(userProfileData));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
      });
  }, []);

  const secondLandingPage = () => {
    var session_key = localStorage.getItem("session_key");
    var secondLandingUrl = `${url}/api/second_landing_page/get_all_second_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(secondLandingUrl, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.content) {
            var content = response.data.data.content;
            for (var i = 0; i < content.length; i++) {
              var contentRow = content[i];
              if (contentRow.full_width == "0") {
                secondLandingPageVerticalDataArray.push(contentRow);
              } else {
                secondLandingPageHorizontalDataArray.push(contentRow);
              }
            }
            setSecondLandingPageHorizontalData(
              secondLandingPageHorizontalDataArray
            );
            setSecondLandingPageVerticalData(
              secondLandingPageVerticalDataArray
            );
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const landingPage = () => {
    var session_key = localStorage.getItem("session_key");
    var landingUrl = `${url}/api/landing_page/get_all_landing_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(landingUrl, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.content) {
            var content = response.data.data.content;
            setLandingPageData(content);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };
  const updateShowKYCNotiffication = () => {
    var session_key = localStorage.getItem("session_key");
    var secondLandingUrl = `${url}/api/my_profile/update_kyc_notification`;
    const headers = {
      session_key: session_key,
    };
    axios
      .post(secondLandingUrl, {}, { headers })
      .then((response) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor</title>
        <meta
          name="description"
          content="Sports Newest and Most Interactive Prediction Game"
        />
      </Helmet>
      <Header showSports={true} />
      <Modal
        show={showReminder}
        onHide={handleReminderClose}
        centered
        className="homeModal"
      >
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowReminder(false);
          }}
        >
          <img
            src="/assets/icons/close.svg"
            style={{ height: "18px", width: "18px" }}
            alt="check-icon"
          />
        </div>
        <Modal.Header>
          <Modal.Title>
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-2 col-sm-12 col">
                <img
                  src="/assets/icons/warning.svg"
                  style={{ height: "60px", width: "68px" }}
                  alt="check-icon"
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-12 kyc_title_warning">
                PLEASE SUBMIT YOUR PROOF OF IDENTITY
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kyc_body_warning">
            Before you start playing Pay to Play Competitions, please submit
            your Proof of Identity documents.
          </div>
          <div className="row mt-5">
            <div className="col-6 d-flex justify-content-end">
              <ButtonSecondary
                textValue={"Close"}
                classValue={`btn_secondary`}
                onClick={() => {
                  setShowReminder(false);
                }}
              />
            </div>
            <div className="col-6 d-flex justify-content-start">
              <ButtonPrimary
                textValue={"Submit"}
                classValue={`btn_primary`}
                onClick={() => {
                  const win = window.open("/sumsub_verification", "_blank");
                  win.focus();
                  setShowReminder(false);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="primary-background">
        <SportsBanner />
        <Summary />
        <SportsContainer horizontalPage={secondLandingPageHorizontalData} />
        {/* <LandingPage landingPageData={landingPageData}> </LandingPage> */}
        <IndexSlider loggedStatus={true} verticalPage={secondLandingPageVerticalData} />
        <Footer />
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
