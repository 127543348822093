import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/Footer";
import "../index/Index.css";
import IndexSlider from "../index/IndexSlider";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import ReactPlayer from "react-player";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import LandingPage from "../landing-page/LandingPage";
import Carousel from 'react-bootstrap/Carousel';
import Countdown from 'react-countdown';

export default function IndexDraft() {
  const [openHowToPlay, setHowToPlay] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  //const [indexHeaderLabels, setIndexHeaderLabels] = useState([])
  //const [indexLabels, setIndexLabels] = useState([])
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [secondLandingPageHorizontalData, setSecondLandingPageHorizontalData] = useState([])
  const [landingPageData, setLandingPageData] = useState([])
  const [secondLandingPageVerticalData, setSecondLandingPageVerticalData] = useState([])
  const [alert, alert_notify] = useAlert();
  
  var aws_url = process.env.REACT_APP_AWS_ASSETS_URL;

  var secondLandingPageHorizontalDataArray = []
  var secondLandingPageVerticalDataArray = []
  const indexMenu = useRef();
  var url = process.env.REACT_APP_API_URL;

  const openHowToPlayContainer = () => {
    setHowToPlay(!openHowToPlay);
  };

  // const getIndexLabels = (page) =>{
  //   var header_labels_url = ""+url+"/api/static_content/get_all_labels"
  //   var body = {
  //     "page_name" : page
  //   }
  //   axios.post(header_labels_url, body)
  //   .then((response) =>{
  //     if(response.status === 200){
  //       var data = response.data.Data.content;
  //       // if(page == 'header'){
  //       //   setIndexHeaderLabels(data)
  //       // }
  //       if(page == 'login form'){
  //         setIndexLabels(data)
  //       }
  //     }
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }

  const goToThisPage = (e) => {
    var path = e.currentTarget.getAttribute("datapath");
    navigate("/" + path + "");
  };

  useEffect(() => {
    secondLandingPage();
    landingPage();
    // getIndexLabels('header')
    // getIndexLabels('login form')
    localStorage.setItem("user_status", "unlogged");
    localStorage.removeItem("session_key");
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    var btn_open_index_menu = document.querySelector(".btn_open_index_menu");
    var btn_open_index_menu_child = document.querySelector(
      ".btn_open_index_menu"
    ).childNodes[0];
    document.addEventListener("click", (e) => {
      if (
        e.target !== btn_open_index_menu &&
        e.target !== btn_open_index_menu_child
      ) {
        if (indexMenu.current && !indexMenu.current.contains(e.target)) {
          setShowMenu(false);
        }
      }
    });

  }, []);

  const secondLandingPage = () =>{
    var session_key = localStorage.getItem("session_key");
    var secondLandingUrl = `${url}/api/second_landing_page/get_all_second_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    console.warn('showLoader bypassed')
    //showLoader();
    axios
      .get(secondLandingUrl, body, { headers })
      .then((response) => {

        if (response.status === 200) {
          if(response.data.data.content){
            var content = response.data.data.content
            var verticalIndex = 1;
            for(var i = 0; i < content.length; i++){
              var contentRow = content[i];
              if(contentRow.full_width == "0"){
                if(verticalIndex <= 3){
                  secondLandingPageVerticalDataArray.push(contentRow)
                }
                verticalIndex += 1
              }else{
                secondLandingPageHorizontalDataArray.push(contentRow)
              }
            }
            setSecondLandingPageHorizontalData(secondLandingPageHorizontalDataArray)
            setSecondLandingPageVerticalData(secondLandingPageVerticalDataArray)
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }

  const landingPage = () =>{
    var session_key = localStorage.getItem("session_key");
    var landingUrl = `${url}/api/landing_page/get_all_landing_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    console.warn('showLoader bypassed')
    //showLoader();
    axios
      .get(landingUrl, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if(response.data.data.content){
            var content = response.data.data.content
            setLandingPageData(content)
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }
  return (
    <React.Fragment>
      <div className="index_container">
        <div
          className="index_header"
          style={{ backgroundImage: "url(assets/image/arena.png)" }}
        >
          <nav className="navbar nav navbar-expand-lg navbar-light">
            <Link to="/" className="logo_icon">
              <span>
                <img
                  src="assets/image/logo-top-predictor.png"
                  alt="top-predictor"
                  className="top_predictor_logo"
                />
              </span>
            </Link>
            <button
              className="navbar-toggler btn_open_index_menu"
              type="button"
              onClick={() => setShowMenu(!showMenu)}
              aria-controls="header-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="assets/icons/navbar.svg" alt="navbar-icon" />
            </button>
            <div
              ref={indexMenu}
              style={showMenu ? { display: "block" } : { display: "none" }}
              className={"collapse navbar-collapse"}
              id="header-navbar"
            >
              <div className="navbar-nav">
                <div className="navbar_button_container">
                  {/* <Link
                    to="/how_to_play"
                    className={
                      openHowToPlayContainer
                        ? "btn_how_to_play active"
                        : "btn_how_to_play"
                    }
                    onClick={openHowToPlayContainer}
                  >
                    {
                      !indexHeaderLabels.how_to_play ? 'HOW TO PLAY' : indexHeaderLabels.how_to_play.label_name
                    }
                    HOW TO PLAY
                  </Link> */}
                  <Link to="/login" className="btn_go_to_login">
                    {/* {
                      !indexLabels.login ? 'LOGIN' : indexLabels.login.label_name
                    } */}
                    LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <div className="index_center">
            <img
              src="assets/image/player.png"
              alt="top-predictor"
              className="guy_happy_mobile_logo"
            />
            <img
              src="assets/image/player_mobile.png"
              alt="top-predictor"
              className="player_mobile"
            />
            <div className="sports_newest_container page_center">
              <span className="sports">SPORTS</span>
              <span className="newest">NEWEST &amp;</span>
              <span className="interactive">MOST INTERACTIVE</span>
              <span className="prediciton">PREDICTION GAME!</span>
            </div>
          </div>
          <div
            className="index_header_center"
            style={{ backgroundImage: "url(assets/image/green_banner.png)" }}
          >
            <div className="index_center account_btns">
              <ButtonPrimary
                classValue="btn_primary btn_index_signup mb-4"
                textValue="PLAY FOR FREE"
                // {
                //   !indexLabels.login_form_sign_up ? 'SIGN UP' : indexLabels.login_form_sign_up.label_name
                // }
                onClick={goToThisPage}
                datapath="register"
              />
              <ButtonSecondary
                classValue="btn_secondary btn_index_login"
                textValue="LOGIN"
                // {
                //   !indexLabels.login ? 'LOGIN' : indexLabels.login.label_name
                // }
                onClick={goToThisPage}
                datapath="login"
              />
            </div>
          </div>
          <div className="index_header_bottom"></div>
        </div>
      </div>
      <div class="container mt-5 mb-5">
        <div class="row">
          <div class="col features">
            <h2 className="display-3" style={{"color": '#539384', 'text-transform': 'uppercase'}}>Play Your Way</h2>


            <div class="container">
              <div class="row mb-4 mt-5">
                <div class="col-lg-2" style={{"text-align": 'right'}}>
                  <i class="fa-solid fa-people-group fa-2x"></i>
                </div>
                <div class="col-lg">
                  Join our community competitions, or set up private competitions against your friends.
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2" style={{"text-align": 'right'}}>
                  <i class="fa-solid fa-stopwatch-20 fa-2x"></i>
                </div>
                <div class="col-lg ">
                  No long term commitment, play when you like.
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2" style={{"text-align": 'right'}}>
                  <i class="fa-solid fa-list-check fa-2x"></i>
                </div>
                <div class="col-lg ">
                  Edit your picks at any time until kick-off.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto">
            <div className="carousel-container-step-by-step">
              <Carousel pause='hover' className="step-by-step">
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100 opacity-75"
                      src="assets/image/mobile_register.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>1. Create an account</h3>
                    <p>Join 1000s of players who compete every week for prizes</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100 opacity-75"
                      src="assets/image/mobile_joincomp.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>2. Join a competition</h3>
                    <p>
                      Join competition and share
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100 opacity-75"
                      src="assets/image/mobile_predict.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>3. Make your predictions</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div>
        
{/*         
        
        <div>
          Test your skills, join a competition within 2 mins!
          Invite your friends.
        </div>
        <div>
        “More than 40 competitions are ready to join today!”
        “Join 500 players from around the world”
        “We host competitions in Soccer, NFL, Basketball in more than 50 leagues”

        </div> */}
        {/* <div>
          <Carousel>
            <Carousel.Item>
              <Carousel.Caption>
                <h3>"I had never realised fantasy football could be so fun!” - Mark, Swansea, UK</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3>"I had never realised fantasy football could be so fun!” - Mark, Swansea, UK</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>      
          
          
        </div>
        <div>
          <Carousel>
            <Carousel.Item>
              <Carousel.Caption>
                <h3>Play Top Predictor for Free</h3>
                <p>Join 1000s of players who compete every week for prizes.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3>Play with your friends</h3>
                <p>image showing additional rb thoughts</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3>Level up and join Pro competitions to win big prizes</h3>
                <p>
                  explain the paid comps (potentially move this to post signup)
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>              
        </div>
        <div >
                        
        </div>
        <div>
          How to get started
            Join a competition, or make your own
            Invite some friends
            Make your predictions
            Check your results
        </div> */}
        {/* Next game kicks off in <Countdown date={Date.now() + 10000} /> */}
      </div>
      <LandingPage landingPageData={landingPageData} />
      {/* <IndexSlider verticalPage={secondLandingPageVerticalData}/> */}
      {/* <div
        className="index_video_player"
        style={{ backgroundImage: "url(assets/image/concrete-wall.png)" }}
      >
        <div
          className="index_video_container"
          style={{ maxWidth: "1440px", margin: "0 auto", height: "100%" }}
        >
          <ReactPlayer
            width={"100%"}
            url="https://www.youtube.com/watch?v=JtNPr2ofeb4"
            muted={true}
            playing={false}
            controls={true}
            style={{ display: "flex", margin: "auto", width: "100%" }}
          />
        </div>
      </div> */}
      <Footer />
      {alert}
      {loader}
    </React.Fragment>
  );
}
