import React, { useState, useEffect } from "react";
import "./RegisterMobileGhana.css";
import "../buttons/Button.css";
import AccountHeader from "../account-header/AccountHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import { useParams } from "react-router-dom";

export default function SMSVerify() {
  const navigate = useNavigate();
  const { number } = useParams();
  var { state } = useLocation();

  if ((number + "").length < 10 || (number + "").length > 14) {
    navigate("/");
  }

  const [code, setCode] = useState("");

  const [registerStatus, setRegisterStatus] = useState(false);
  const [validUsername, setValidUsername] = useState(false);
  const [validCode, setValidCode] = useState(false);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [validCountry, setValidCountry] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  const [registerLabels, setRegisterLabels] = useState([]);

  var registerClassVal = "";
  var url = process.env.REACT_APP_API_URL;

  // const registerClickFunc = () => {
  //     setRegisterStatus(!registerStatus)
  // }

  if (registerStatus) {
    registerClassVal =
      "btn_register_user btn_account_action btn_primary active";
  } else {
    registerClassVal = "btn_register_user btn_account_action btn_primary";
  }

  const getRegisterInputData = () => {
    var register_code = document.getElementById("input_register_code").value;

    if (register_code !== "") {
      document
        .getElementById("input_register_code_required")
        .classList.add("d-none");
      setValidCode(true);
    } else {
      document
        .getElementById("input_register_code_required")
        .classList.remove("d-none");
      setValidCode(false);
    }

    if (validCode) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();

    var register_code = document.getElementById("input_register_code").value;
    var body = {
      otp: register_code,
      phone_no: number.split(" ").join(""),
      ...state,
    };

    var headers = {};
    if (isValidated()) {
      showLoader();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/auth/ghana_verify_otp`,
          body,
          { headers }
        )
        .then(function (response) {
          var response_data = response;
          hideLoader();
          if (response_data.status === 200) {
            //navigate("/login");
            if (state != null) {
              alert_notify(
                "success",
                "Registed successfully. Please login to your account"
              );
              //setSuccesStatus(true);
              setTimeout(() => {
                navigate("/login");
              }, 3000);
            } else {
              console.log("here");
              if (response_data.data?.Data) {
                console.log("here inside");
                navigate(`/reset-password/${response_data.data?.Data}`);
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            debugger;
            alert_notify(
              "error",
              err?.response?.data?.Message?.length > 0
                ? err?.response?.data?.Message
                : err?.response?.data?.Error[
                    Object.keys(err?.response?.data?.Error)[0]
                  ]
            );
          }
          hideLoader();
        });
    }
  };

  const getRegisterLabels = () => {
    var register_labels_url = "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "register form",
    };
    axios
      .post(register_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setRegisterLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRegisterLabels();
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    setSuccesStatus(false);
  }, []);

  const showError = (field) => {
    let message = "";
    if (field.toLowerCase() === "password") {
      message =
        "Password is Invalid. Please Enter 8 digit Alpha Numeric password which also includes 1 special chracter";
    } else if (field.toLowerCase() === "confirm password") {
      message = "Confirm Password does not matched";
    } else if (field.toLowerCase() === "code") {
      message = "Please enter a valid code";
    } else if (field.toLowerCase() === "country") {
      message = "Please select country from dropdown";
    } else if (field.toLowerCase() === "name") {
      message = "First Name and Last Name is required";
    } else if (field.toLowerCase() === "terms") {
      message = "Please agree to our Terms below.";
    } else message = field + " is not valid";

    alert_notify("error", message);
  };

  const isValidated = () => {
    if (/^\d{4,8}$/.test(code)) {
      return true;
    } else {
      showError("code");
      return false;
    }
  };

  console.log(registerLabels);
  return (
    <React.Fragment>
      <LoginHeader loginValue={false} />

      <AccountHeader
        headerValue="VERIFY YOUR MOBILE NUMBER"
        firstTextValue="You need to do this before you can start playing!"
        secondTextValue=""
      />

      <div className="register_container">
        {successStatus ? (
          <SuccesNotification
            title="Thank you for verifying your mobile number"
            content={"..."}
          />
        ) : (
          <div className="page_content_center">
            <form id="register_form" onSubmit={handleRegisterSubmit}>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-3 text-right">
                  <p>
                    A verification SMS has been sent to{" "}
                    <strong>{number}</strong>.
                  </p>
                  Please enter your code to get started:
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="number"
                    className="register_input"
                    id="input_register_code"
                    name="register_code"
                    placeholder={"verfication code"}
                    value={code}
                    onChange={(e) => {
                      if ((e.target.value + "").length <= 6) {
                        setCode(e.target.value);
                      }
                    }}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_code_required"
                  >
                    {" code is required"}
                  </span>
                </div>
              </div>

              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 d-flex register_now_container">
                  <ButtonPrimary
                    classValue={registerClassVal}
                    textValue="VALIDATE"
                    form="register_form"
                    type="submit"
                    onClick={getRegisterInputData}
                    // d={!isValidated()}
                  />
                </div>
              </div>
            </form>
            <div className="row justify-content-center mt-row">
              <div className="col-12 col-lg-5 col-xl-5 mt-2 d-flex align-items-center justify-content-center">
                {/* <span className="already_have_acc_text">
                  Haven't received a message?
                </span> */}
                {/* <Link to="/login" className="btn_already_have_acc mt-2" style={{textTransform: 'uppercase', "font-size":"smaller", "margin-left": 0}}>Resend Verification Code</Link> */}
              </div>
            </div>
          </div>
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
