import React, { useState, useEffect } from "react";
import "./RegisterMobileGhana.css";
import "../buttons/Button.css";
import AccountHeader from "../account-header/AccountHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import Carousel from 'react-bootstrap/Carousel';

function encryptPassword(password) {
  return btoa(password);
}

export default function RegisterMobile() {
  const register_phone_prefix = "233"; // Ghana

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("+"+register_phone_prefix);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [terms, setTermsCheck] = useState(false);
  const [promotions, setPromotionsCheck] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [validUsername, setValidUsername] = useState(false);
  const [validMobile, setValidMobile] = useState(false);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  const [registerLabels, setRegisterLabels] = useState([])

  

  var registerClassVal = "";
  var url = process.env.REACT_APP_API_URL;

  // const registerClickFunc = () => {
  //     setRegisterStatus(!registerStatus)
  // }

  if (registerStatus) {
    registerClassVal =
      "btn_register_user btn_account_action btn_primary active";
  } else {
    registerClassVal = "btn_register_user btn_account_action btn_primary";
  }

  const showPasswordStatus = () => {
    setShowPassword(!showPassword);
  };

  const showRepeatPasswordStatus = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const getRegisterInputData = () => {
    var register_username = document.getElementById(
      "input_register_username"
    ).value;
    var register_mobile = document.getElementById("input_register_mobile").value;
    var register_firstname = document.getElementById(
      "input_register_firstname"
    ).value;
    var register_lastname = document.getElementById(
      "input_register_lastname"
    ).value;
    var register_password = document.getElementById(
      "input_register_password"
    ).value;
    var register_confirm_password = document.getElementById(
      "input_register_confirmed_password"
    ).value;

    if (register_username !== "") {
      document
        .getElementById("input_register_username_required")
        .classList.add("d-none");
      setValidUsername(true);
    } else {
      document
        .getElementById("input_register_username_required")
        .classList.remove("d-none");
      setValidUsername(false);
    }

    if (register_mobile !== "") {
      document
        .getElementById("input_register_mobile_required")
        .classList.add("d-none");
      setValidMobile(true);
    } else {
      document
        .getElementById("input_register_mobile_required")
        .classList.remove("d-none");
      setValidMobile(false);
    }

    if (register_firstname !== "") {
      document
        .getElementById("input_register_firstname_required")
        .classList.add("d-none");
      setValidFirstName(true);
    } else {
      document
        .getElementById("input_register_firstname_required")
        .classList.remove("d-none");
      setValidFirstName(false);
    }

    if (register_lastname !== "") {
      document
        .getElementById("input_register_lastname_required")
        .classList.add("d-none");
      setValidLastName(true);
    } else {
      document
        .getElementById("input_register_lastname_required")
        .classList.remove("d-none");
      setValidLastName(false);
    }

    if (register_password !== "") {
      document
        .getElementById("input_register_password_required")
        .classList.add("d-none");
      setValidPassword(true);
    } else {
      document
        .getElementById("input_register_password_required")
        .classList.remove("d-none");
      setValidPassword(false);
    }

    if (register_confirm_password !== "") {
      document
        .getElementById("input_register_confirm_required")
        .classList.add("d-none");
      setValidConfirmPassword(true);
    } else {
      document
        .getElementById("input_register_confirm_required")
        .classList.remove("d-none");
      setValidConfirmPassword(false);
    }

    if (validPassword && validConfirmPassword) {
      if (
        encryptPassword(register_password) ===
        encryptPassword(register_confirm_password)
      ) {
        setAllValid(true);
      } else {
        setAllValid(false);
      }
    }
    if (
      validUsername &&
      validMobile &&
      validFirstName &&
      validLastName &&
      validPassword &&
      validConfirmPassword
    ) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();

    var register_url = "" + url + "/api/auth/signup";

    var register_username = document.getElementById(
      "input_register_username"
    ).value.trim();
    var register_mobile = document.getElementById("input_register_mobile").value;
    var register_firstname = document.getElementById(
      "input_register_firstname"
    ).value;
    var register_lastname = document.getElementById(
      "input_register_lastname"
    ).value;
    var register_password = document.getElementById(
      "input_register_password"
    ).value;
    var register_confirm_password = document.getElementById(
      "input_register_confirmed_password"
    ).value;
    var register_country_code = "83"; // Ghana
    
    // cache username on the client
    localStorage.setItem("cached_username", register_username);

    var oneTimeEmailAddress = register_mobile + '.m@toppredictor.com';
    var body = {
      address1: "",
      address2: "",
      affiliate_code: null,
      city: "",
      confirm_password: encryptPassword(register_password),
      country_code: register_country_code,
      dob_date: null,
      mobile: register_mobile,
      email: oneTimeEmailAddress,
      first_name: register_firstname,
      isAfrica: false,
      isSimple: true,
      last_name: register_lastname,
      opt_in_email: "0",
      password: encryptPassword(register_confirm_password),
      //phone_no: "+" + register_phone_prefix + register_mobile,
      phone_no: register_mobile,
      postcode: "",
      username: register_username,
    };

    var headers = {};

    if (isValidated()) {
      showLoader();
      axios
        .post(register_url, body, { headers })
        .then(function (response) {
          var response_data = response;
          //hideLoader();
          if (response_data.status === 200) {
            // setSuccesStatus(true);

            generateCodeAndRedirect(body.phone_no);

            //   setTimeout(() => {
            //     navigate("/login");
            //   }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            debugger;
            var errText = err?.response?.data?.Error[
              Object.keys(err?.response?.data?.Error)[0]
            ]

            // we're using mobile reg, so if we produce a matching email address the phonenumber has already been used to create an account via register-ghana
            if (errText.includes('This email address is already registered')) {
              errText = 'This mobile number is already registered on another account, please log into that account or contact info@toppredictor.com, if you have forgotten your log-in details.'
            }

            alert_notify(
              "error",
              errText
            );
          }
          hideLoader();
        });
    }
  };

  const generateCodeAndRedirect = (phoneNumber) => {
    var phoneNumberWithNoPlus = phoneNumber.substring(1);
        
    var mob_verify_url = "https://mtech-sms.onrender.com/api/verify_mobile_start";
    var body = {
      number: phoneNumberWithNoPlus
    };
    var headers = {};

    axios
        .post(mob_verify_url, body, { headers })
        .then(function (response) {
          var response_data = response;
          //hideLoader();
          if (response_data.status === 201) {
            // setSuccesStatus(true);

            navigate('/register-ghana/verify/'+phoneNumberWithNoPlus)

            //   setTimeout(() => {
            //     navigate("/login");
            //   }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
              navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            debugger;
            console.log({err})
            alert_notify(
              "error",
              err?.response?.data?.message
            );
          }
          hideLoader();
        });
  }

  const getRegisterLabels = () =>{
    var register_labels_url = ""+url+"/api/static_content/get_all_labels"
    var body = {
      "page_name" : "register form"
    }
    axios.post(register_labels_url, body)
    .then((response) =>{
      if(response.status === 200){
        var data = response.data.Data.content;
        setRegisterLabels(data)
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(() => {
    getRegisterLabels();
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    setSuccesStatus(false);
  }, []);

  const showError = (field) => {
    let message = "";
    if (field.toLowerCase() === "password") {
      message =
        "Password is Invalid. Please Enter 8 digit Alpha Numeric password which also includes 1 special chracter";
    } else if (field.toLowerCase() === "confirm password") {
      message = "Confirm Password does not matched";
    } else if (field.toLowerCase() === "mobile") {
      message = "Please enter a valid mobile number starting with +" + register_phone_prefix;
    } else if (field.toLowerCase() === "name") {
      message = "First Name and Last Name is required";
    } else if (field.toLowerCase() === "terms") {
      message = "Please agree to our Terms below.";
    } else message = field + " is not valid";

    alert_notify("error", message);
  };

  const isValidated = () => {
    if (
      username.length > 2 &&
      username.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/gi) === null
    ) {
      if (/^\+\d{12,12}$/.test(mobile)) { // set to min 12 and max 12 digits with a + beforehand
        if (firstName !== "" || lastName !== "") {
          if (
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
              password
            )
          ) {
            if (confirmPassword === password) {
              
              if (terms) {
                return true;
              } else {
                showError("Terms");
                return false;
              }

            } else {
              showError("Confirm Password");
              return false;
            }
          } else {
            showError("Password");
            return false;
          }
        } else {
          showError("Name");
          return false;
        }
      } else {
        showError("Mobile");
        return false;
      }
    } else {
      showError("User Name");
      return false;
    }

    //  return (
    //    confirmPassword === password &&
    //    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
    //    username.length > 2 &&
    //    password.length > 7
    //  );
  };

  //console.log(registerLabels)
  return (
    <React.Fragment>
      {/* <LoginHeader loginValue={false} /> */}
      <div className="index_container">
        <div
          className="index_header ghana_index_header"
          style={{ backgroundImage: "url(assets/image/arena.png)" }}
        >
          <nav className="navbar nav navbar-expand-lg navbar-light">
            <Link to="/" className="logo_icon">
              <div>
                <span>
                  <img
                    src="assets/image/logo-top-predictor.png"
                    alt="top-predictor"
                    className="ghana_top_predictor_logo"
                  />
                </span>
              </div>
            </Link>
            <div style={{"z-index": "3", "float": "right"}}>
              <a href="#form" className={"go-to-register"}>
                Join Now
              </a>
            </div>
            {/* <div
              style={false ? { display: "block" } : { display: "none" }}
              className={"collapse navbar-collapse"}
              id="header-navbar"
            >
              <div className="navbar-nav">
                <div className="navbar_button_container">
                  
                </div>
              </div>
            </div> */}
          </nav>




          <div className="index_center">
            <img
              src="assets/image/player.png"
              alt="top-predictor"
              className="ghana_guy_happy_mobile_logo"
            />
            <img
              src="assets/image/player_mobile.png"
              alt="top-predictor"
              className="ghana_player_mobile"
            />
            <div className="sports_newest_container page_center">
              <span className="sports">TOP&nbsp; PREDICTOR'S &nbsp;</span>
              <span className="newest">EXCITING</span>
              {/* <span className="interactive">EXCITING</span> */}
              <span className="prediciton">PREDICTION&nbsp; GAME!</span>
            </div>
          </div>
          <div
            className="index_header_center"
            style={{ backgroundImage: "url(assets/image/green_banner.png)" }}
          >
            {/* <div className="index_center account_btns">
              
            </div> */}
          </div>
          <div className="index_header_bottom"></div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col feature-col">
            <h2 className="display-3 heading-ghana" style={{"color": '#539384', 'text-transform': 'uppercase'}}>Win Data Prizes</h2>

            <div className="container">
              <div className="row pb-2 mb-2 prize-1">
                <div className="col-lg-2" style={{"text-align": 'center'}}>
                  <i className="fa-solid fa-medal icon"></i><i className="fa-solid fa-medal icon extra-icon"></i><i className="fa-solid fa-medal icon extra-icon"></i>
                </div>
                <div className="col-lg text">
                  <strong>5</strong> Gigabytes
                </div>
              </div>
              <div className="row pb-2 mb-2 prize-2">
                <div className="col-lg-2" style={{"text-align": 'center'}}>
                <i className="fa-solid fa-medal icon"></i><i className="fa-solid fa-medal icon extra-icon"></i>
                </div>
                <div className="col-lg">
                  <strong>3</strong> Gigabytes
                </div>
              </div>
              <div className="row mb-4 prize-3">
                <div className="col-lg-2" style={{"text-align": 'center'}}>
                  <i className="fa-solid fa-medal icon"></i>
                </div>
                <div className="col-lg ">
                  <strong>1</strong> Gigabyte
                </div>
              </div>
            </div>

            <h2 className="display-3 heading-ghana" style={{"color": '#539384', 'text-transform': 'uppercase'}}>Make picks in seconds</h2>

            <div className="container">
              {/* <div className="row pb-2 mb-2 mt-5 border-bottom">
                <div className="col-lg-2" style={{"text-align": 'center'}}>
                  <i className="fa-solid fa-people-group fa-2x"></i>
                </div>
                <div className="col-lg">
                  Join our community competitions, or set up private competitions against your friends.
                </div>
              </div> */}
              <div className="mb-4 additional-info">
                  Change your picks at any time until kick-off and Play your Wildcards wisely to <span class="icon-card icon-double">Double</span> or <span class="icon-card icon-triple">Triple</span> your scores.
              </div>
              {/* <span className="prize-limits">*Available in specific weekly compeitions in April and May.</span> */}
            </div>
          </div>
          <div className="col-md-auto rounded-7">
            <div className="carousel-container-step-by-step">
              <Carousel pause='hover' className="step-by-step">
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100"
                      src="assets/image/mobile_register2.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>1. Create an account</h3>
                    <p>Join 1000s of players who compete every week for prizes</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100"
                      src="assets/image/mobile_joincomp.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>2. Join a competition</h3>
                    <p>
                      Join competition in seconds
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100"
                      src="assets/image/predict_city_draw.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>3. Make your predictions</h3>
                    <p>Go for a score draw</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100"
                      src="assets/image/predict_city_arsenal.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>4. Change your mind</h3>
                    <p>Or back the Gunners</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{"padding-bottom":"0px", "background-color": '#fff'}}>
                    <img
                      className="d-block w-100"
                      src="assets/image/mobile_leaderboard.png"
                      alt="Mobile Predictions"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>5. Check your progress</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <a name="form" />
      <AccountHeader
        headerValue="SIGN UP NOW"
        firstTextValue="Fill in the form below to join for FREE and make your predictions today!"
        secondTextValue=""
      />
      <div className="register_container">
        {successStatus ? (
          <SuccesNotification
            title="Thank you for signing up"
            content={
              "We have sent you a verification sms. Please check your mobile phone, if you don’t receive it."
            }
          />
        ) : (
          <div className="page_content_center">
            <div className="row justify-content-center mt-row">
              <div className="col-12 col-lg-5 col-xl-5 mt-2 d-flex align-items-center justify-content-center">
                <span className="already_have_acc_text">
                  Already a member?
                </span>
                <Link to="/login" className="btn_already_have_acc" style={{textTransform: 'uppercase'}}>
                  {
                    !registerLabels.reg_form_login_label ? 'LOGIN' : registerLabels.reg_form_login_label.label_name
                  }
                </Link>
              </div>
            </div>
            <form id="register_form" onSubmit={handleRegisterSubmit}>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_mobile"
                    name="register_mobile"
                    placeholder={' *Mobile Phone Number'}
                    value={mobile}
                    onChange={(e) => 
                      {
                        // if ((e.target.value+'').length <= 12){
                          setMobile(e.target.value);
                        // }
                      }
                    }
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_mobile_required"
                  >
                    { 
                      ' Mobile is required'
                    } 
                  </span>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_username"
                    name="register_username"
                    placeholder= { 
                      !registerLabels.reg_form_username_label ? '*Username' 
                      : '*' +registerLabels.reg_form_username_label.label_name
                    }
                    value={username}
                    onChange={(e) =>
                      setUsername(
                        e.target.value.replaceAll(
                          /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/gi,
                          ""
                        )
                      )
                    }
                    maxLength="25"
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_username_required"
                  >
                    {
                      !registerLabels.err_reg_form_username_req ? ' Username is required' 
                      : registerLabels.err_reg_form_username_req.label_name
                    }
                  </span>
                </div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_firstname"
                    name="register_firstname"
                    placeholder= { 
                      !registerLabels.reg_form_first_name_label ? '* First name' 
                      : '*' + registerLabels.reg_form_first_name_label.label_name
                    } 
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_firstname_required"
                  >
                    { 
                      !registerLabels.err_reg_form_fname_req ? 'First name is required' 
                      : registerLabels.err_reg_form_fname_req.label_name
                    } 
                  </span>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_lastname"
                    name="register_lastname"
                    placeholder={ 
                      !registerLabels.reg_form_last_name_label ? '* Last name' 
                      : '*' + registerLabels.reg_form_last_name_label.label_name
                    }
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_lastname_required"
                  >
                    { 
                      !registerLabels.err_reg_form_lname_req ? 'Last name is required' 
                      : registerLabels.err_reg_form_lname_req.label_name
                    } 
                  </span>
                </div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2 position-relative">
                  <span className="show_password" onClick={showPasswordStatus}>
                    <img
                      src="assets/icons/show.svg"
                      alt="show-icon"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="register_input"
                    id="input_register_password"
                    name="register_password"
                    placeholder={ 
                      !registerLabels.reg_form_pwd_label ? '*Password' 
                      : '*' + registerLabels.reg_form_pwd_label.label_name
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_password_required"
                  >
                    { 
                      !registerLabels.err_reg_form_pwd_req ? 'Password is required' 
                      : registerLabels.err_reg_form_pwd_req.label_name
                    } 
                  </span>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2 position-relative">
                  <span
                    className="show_password"
                    onClick={showRepeatPasswordStatus}
                  >
                    <img
                      src="assets/icons/show.svg"
                      alt="show-icon"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <input
                    type={showRepeatPassword ? "text" : "password"}
                    className="register_input"
                    id="input_register_confirmed_password"
                    name="register_confirm_password"
                    placeholder={ 
                      !registerLabels.reg_form_cnf_pwd_label ? '*Confirm Password' 
                      : '*' + registerLabels.reg_form_cnf_pwd_label.label_name
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_confirm_required"
                  >
                    { 
                      !registerLabels.err_reg_form_cnf_pwd_req ? 'Enter the password again for confirmation' 
                      : registerLabels.err_reg_form_cnf_pwd_req.label_name
                    }
                  </span>
                </div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <input
                    type="text"
                    disabled="disabled"
                    className="register_input"
                    id="register_country_select"
                    name="register_country"
                    value="Ghana"
                  />
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2"></div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2 ">
                  <div className="d-flex align-items-center register_terms">
                    <input
                      type="checkbox"
                      className="input_register_accept_terms register_checkbox"
                      style={{ width: "23px" }}
                      value={terms}
                      onClick={(e) => {
                        setTermsCheck(!terms);
                      }}
                    />
                    <span>
                      
                      {
                        !registerLabels.reg_form_agree_tc_label ? 'I agree to the TOP PREDICTOR' 
                        : registerLabels.reg_form_agree_tc_label.label_name
                      }
                      <Link to="/terms_conditions">
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#12332E",
                            margin: "0 5px",
                          }}
                        >
                          terms and conditions
                        </b>
                      </Link>{" "}
                      and
                      <Link to="/privacy_policy">
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#12332E",
                            margin: "0 5px",
                          }}
                        >privacy policy</b>
                      </Link>.
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2 d-flex justify-content-end"></div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <div className="d-flex align-items-center register_terms">
                    <input
                      type="checkbox"
                      className="input_register_agree_marketing_terms register_checkbox"
                      value={promotions}
                      onClick={(e) => {
                        // setPromotionsCheck(!promotions);
                      }}
                    />
                    <span>
                    {
                      !registerLabels.reg_form_marketing_label ? 'By ticking this box, I agree to marketing and promotional communication from TOP PREDICTOR.' 
                        : registerLabels.reg_form_marketing_label.label_name
                    }
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 d-flex register_now_container">
                  <ButtonPrimary
                    classValue={registerClassVal}
                    textValue={'Register Now'}
                    form="register_form"
                    type="submit"
                    onClick={getRegisterInputData}
                    // d={!isValidated()}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
