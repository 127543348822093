import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import "./rules.css";
import axios from "axios";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import { useNavigate } from 'react-router-dom';
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";

export default function Rules() {
  const [loggedStatus, setLoggedStatus] = useState("");
  const [rulesContentText, setRulesContentText] = useState("");
  const [rulesName, setRulesName] = useState("");
  var url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    var rules_url = "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "rules" };
    showLoader();
    axios
      .post(rules_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setRulesContentText(page_content);
          var page_name = data.content.page_name;
          setRulesName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Rules</title>
        <meta
          name="description"
          content="Here are the rules about the competitions, entry fees and competition prizes. Get all the game play rules here. "
        />
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="rules_container row m-0 justify-content-between">
        <div className="page_content_center">
          <div className="rules_title col-md-12">
            <h2 className="text-uppercase">{Parser(rulesName)}</h2>
          </div>
          <div className="rules_content col-md-12">
            {Parser(rulesContentText)}
          </div>
          {/* <div className="rules_content col-md-12">
                        <span className="rules_content_tittle">Competitions</span>
                        <ul className="mt-3">
                            <li>
                                Each Competition has a maximum permitted number of entries.
                            </li>
                            <li>
                                A player may only have one entry per Competition.
                            </li>
                            <li>
                                A player must use their one and only username for all entries. Top Predictor reserves the right to disqualify any player using more than one account to play in its competitions.
                            </li>
                            <li>
                                Predictions must be made before each match or event begins
                            </li>
                        </ul>
                        <span className="rules_content_tittle">Entry Fees</span>
                        <ul className="mt-3">
                            <li>
                                With the exception of F2P (free to play) Competitions, each Competition has an entry fee.
                            </li>
                            <li>
                                Competition entry fees range from 1 Euro.
                            </li>
                            <li>
                                As long as two players enter a competition it will go ahead.
                            </li>
                            <li>
                                Competitions range from one round.
                            </li>
                            <li>
                                The entry fee is debited from the player’s account when they click on “Play now”, “Challenge” or accept a "Fundraising" or "Private Competition" invitation. It is the player’s responsibility to make their predictions after that point.
                            </li>
                            <li>
                                Players may not cancel an entry once it has been confirmed and the entry fee has been debited from their account
                            </li>
                        </ul>
                        <span className="rules_content_tittle">Competion Prizes</span>
                        <ul className="mt-3">
                            <li>
                                Prize funds are determined by the total amount paid into each competition less our administration fee which varies between jurisdictions, and will be no less than 10%.
                            </li>
                            <li>
                                The “Current guaranteed prize fund” will be paid out so long as the Competition is filled before the first match begins. 
                            </li>
                            <li>
                                For Competitions that are not filled when the first match begins, prizes will be pro-rated accordingly.
                            </li>
                            <li>
                                If all places in the Competition are not filled, the Competition may be cancelled at Top Predictor’s discretion.
                            </li>
                            <li>
                                Notification of any cancellations will be made by email or SMS wherever possible. This will take place at the scheduled start of the first fixture of the Competition, and the player’s entry fee will be refunded to their account.
                            </li>
                        </ul>
                    </div> */}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
