import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import LoginHeader from "../login/LoginHeader";
import "../feedback/feedback.css";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import SuccesNotification from "../../hooks/SuccesNotification";
import { Helmet } from "react-helmet";

export default function Feedback() {
  const [loggedStatus, setLoggedStatus] = useState("");
  const [userDataUserId, setUserId] = useState();
  const [fullNameValid, setFullNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [successStatus, setSuccesStatus] = useState(false);
  const [feedbackLabels, setFeedbackLabels] = useState([])
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var classVal = "";
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");
  const navigate = useNavigate();

  var loggedUserData = JSON.parse(localStorage.getItem("userData"));
  const getFeedbackData = () => {
    var feedback_message = document.getElementById(
      "input_feedback_message"
    ).value;

    if (!userDataUserId) {
      var full_name = document.getElementById("input_feedback_fullname").value;
      var email = document.getElementById("input_feedback_email").value;
      if (full_name !== "") {
        setFullNameValid(true);
      } else {
        setFullNameValid(false);
      }

      if (email !== "") {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if (feedback_message !== "") {
      setMessageValid(true);
    } else {
      setMessageValid(false);
    }
  };

  const sendFeedbackData = (e) => {
    e.preventDefault();
    var feeback_url = "" + url + "/api/feedback/send_feedback";

    if (!userDataUserId) {
      if (fullNameValid && emailValid && messageValid) {
        var full_name = document.getElementById(
          "input_feedback_fullname"
        ).value;
        var email = document.getElementById("input_feedback_email").value;
        var feedback_message = document.getElementById(
          "input_feedback_message"
        ).value;

        var body = {
          email: email,
          msg: feedback_message,
          name: full_name,
        };
        showLoader();
        axios
          .post(feeback_url, body, { headers })
          .then(function (response) {
            hideLoader();
            if (response.status === 200) {
              setSuccesStatus(true);
              alert_notify("success", response.data.Message);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              navigate("/login");
              localStorage.removeItem("session_key");
            } else {
              alert_notify("error", err.response.data.Error.email);
            }
            hideLoader();
          });
      } else {
        alert_notify("error", "Please fill required data.");
      }
    } else {
      var feedback_message = document.getElementById(
        "input_feedback_message"
      ).value;
      if (messageValid) {
        var body = {
          email: loggedUserData.email,
          msg: feedback_message,
          name: loggedUserData.first_name + " " + loggedUserData.last_name,
        };
        var headers = {
          session_key: session_key,
        };
        showLoader();
        axios
          .post(feeback_url, body, { headers })
          .then(function (response) {
            hideLoader();
            if (response.status === 200) {
              setSuccesStatus(true);
              alert_notify("success", response.data.Message);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              navigate("/login");
              localStorage.removeItem("session_key");
            } else {
              alert_notify("error", err.response.data.Message);
            }
            hideLoader();
          });
      } else {
        alert_notify("error", "Please fill required data.");
      }
    }
  };

  const getFeedbackLabels = () =>{
    var feedback_labels_url = ""+url+"/api/static_content/get_all_labels"
    var body = {
      "page_name" : "contact us"
    }
    axios.post(feedback_labels_url, body)
    .then((response) =>{
      if(response.status === 200){
        var data = response.data.Data.content;
        setFeedbackLabels(data)
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  classVal = "btn_submit_feedback btn_primary mr-2";
  useEffect(() => {
    getFeedbackLabels()
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    if (loggedUserData !== null) {
      setUserId(loggedUserData.user_id);
    }
    setSuccesStatus(false);
  }, []);

  console.log(feedbackLabels)
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Feedback</title>
        <meta
          name="description"
          content="Customer Service. We're listening. How can we help you today? Please use the following form to send us your comments or feedback."
        />
      </Helmet>
      {!userDataUserId ? <LoginHeader /> : <Header />}
      <ImageContainer />
      <div className="feedback_container">
        <div className="page_center">
          {successStatus ? (
            <SuccesNotification
              title="Thank you for contacting us."
              content="We have received your message. Someone from our team will contact you soon."
            />
          ) : (
            <div>
              <div className="feedback_text" style={{textTransform:'uppercase'}}>
                <h2>
                {
                  !feedbackLabels.feedback_page_label ? 'Contact Us' : feedbackLabels.feedback_page_label.label_name
                }
                </h2>
              </div>
              <div className="row m-0 feedback_center mt-5 justify-content-center">
                <div className="col-12 col-lg-8 col-xl-8 d-flex justify-content-center">

                  {
                  !feedbackLabels.feedback_description_label ? "support@toppredictor.com - We're listening. How can we help you today?" : feedbackLabels.feedback_description_label.label_name
                }
                </div>
              </div>
              <form id="feedback_form" onSubmit={sendFeedbackData}>
                {!userDataUserId ? (
                  <div className="row m-0 feedback_textarea_container mt-5 justify-content-center">
                    <div className="col-12 col-lg-4 col-xl-4 d-flex justify-content-center pb-1">
                      <input
                        type="text"
                        className="feedback_input"
                        id="input_feedback_fullname"
                        placeholder={
                          !feedbackLabels.feedback_fname_label ? '* Full Name' : '* ' + feedbackLabels.feedback_fname_label.label_name
                        }
                      />
                      {/* <span className="required_message d-none" id="input_feedback_fname_required">Full name is required</span> */}
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 d-flex justify-content-center pb-1">
                      <input
                        type="text"
                        className="feedback_input"
                        id="input_feedback_email"
                        placeholder={
                          !feedbackLabels.feedback_email_label ? '* Email Address' : '* ' + feedbackLabels.feedback_email_label.label_name
                        }
                      />
                      {/* <span className="required_message d-none" id="input_feedback_email_required">Email is required</span> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row m-0 feedback_textarea_container mt-4 justify-content-center">
                  <div className="col-12 col-lg-8 col-xl-8 d-flex justify-content-center">
                    <textarea
                      className="feedback_textarea"
                      id="input_feedback_message"
                      placeholder={
                        !feedbackLabels.feedback_message_label ? 'Type your message here' : feedbackLabels.feedback_message_label.label_name
                      }
                    ></textarea>
                    {/* <span className="required_message d-none" id="input_feedback_message_required">Message is required</span> */}
                  </div>
                </div>
                <div className="row m-0 mt-5 justify-content-center">
                  <div className="col-12 col-lg-8 col-xl-8 d-flex justify-content-end">
                    <ButtonPrimary
                      textValue={
                        !feedbackLabels.feedback_submit_label ? 'Submit' : feedbackLabels.feedback_submit_label.label_name
                      }
                      classValue={classVal}
                      form="feedback_form"
                      type="submit"
                      onClick={getFeedbackData}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
