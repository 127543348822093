function playerPositionSort(arr) {
  arr.sort((a, b) => {
    if (
      (a.startsWith("G") && b.startsWith("G")) ||
      (!a.startsWith("G") && !b.startsWith("G"))
    ) {
      return 0;
    }
    // If a starts with "G", move it to the bottom
    else if (a.startsWith("G")) {
      return 1;
    }
    // If b starts with "G", move it to the top
    else {
      return -1;
    }
  });
  return arr;
}

export { playerPositionSort };
