import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate, useParams } from "react-router-dom";
import "../private-competitions/PrivateCompetition.css";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import ProfileAccount from "../account/ProfileAccount";
import Parser from "html-react-parser";

export default function CreatePrivateCompetition() {
  const [loader, showLoader, hideLoader] = useLoading();
  const [sports, setSports] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, alert_notify] = useAlert();
  const [validSport, setValidSport] = useState(false);
  const [validLeague, setValidLeague] = useState(false);
  const [successStatus, setSuccesStatus] = useState(false);
  const [entryFee, setEntryFee] = useState("");
  const [entrants, setEntrants] = useState("");
  const { sportId, leagueId } = useParams();
  const [selectLeagueid, setSelectLeagueId] = useState(leagueId);
  const [selectSportid, setSelectSportId] = useState(sportId);
  var url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  var session_key = localStorage.getItem("session_key");
  const [cpLabels, setCpLabels] = useState([]);
  const [cpStaticContent, setCpStaticContent] = useState();

  const getPrivateCompetitionData = () => {
    var private_competition_sport_get = document.getElementById(
      "input_private_competition_sport"
    ).value;
    var private_competition_league_get = document.getElementById(
      "input_private_competition_league"
    ).value;

    if (private_competition_sport_get !== "0") {
      setValidSport(true);
    } else {
      setValidSport(false);
    }

    if (private_competition_league_get !== "0") {
      setValidLeague(true);
    } else {
      setValidLeague(false);
    }
  };

  const getCreatePrivateCompetitionStaticContent = () => {
    var content_url = "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "create_private_competiton" };
    showLoader();
    axios
      .post(content_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var pageContent = data.content.page_content;
          if (pageContent != null && pageContent != undefined) {
            setCpStaticContent(pageContent);
          } else {
            setCpStaticContent(
              "Please select one option only from each of the following:"
            );
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const handleSportChange = (event) => {
    setSelectSportId(event.target.value);
    var leagues_html = `<option value="0">League</option>`;
    var sport_id = document.getElementById(
      "input_private_competition_sport"
    ).value;
    var sport_leagues = leagues.filter((row) => {
      return row.sport_id === sport_id;
    });
    sport_leagues.map((league, index) => {
      return (leagues_html +=
        `
                    <option data-id="` +
        league.league_id +
        `" value="` +
        league.league_id +
        `" key="` +
        index +
        `">` +
        league.league_name +
        `</option>
                `);
    });
    document.getElementById("input_private_competition_league").innerHTML =
      leagues_html;
  };

  const submitCreatePrivateCompetition = (e) => {
    e.preventDefault();
    var create_private_competition_url =
      "" + url + "/api/create_contest/request_contest";

    var private_competition_sport = document.getElementById(
      "input_private_competition_sport"
    ).value;
    var private_competition_league = document.getElementById(
      "input_private_competition_league"
    ).value;
    var private_competition_entry_fee = document.getElementById(
      "input_private_competition_entry_fee"
    ).value;
    var private_competition_entrants = document.getElementById(
      "input_private_competition_entrants"
    ).value;

    var league_el = document.getElementById("input_private_competition_league");
    var sport_el = document.getElementById("input_private_competition_sport");
    private_competition_league =
      league_el.options[league_el.selectedIndex].getAttribute("data-id");
    private_competition_sport =
      sport_el.options[sport_el.selectedIndex].getAttribute("data-id");

    if (validSport && validLeague) {
      var body = {
        competition_title: "Private Contest",
        contest_type: "private",
        entry_fee: private_competition_entry_fee,
        league_id: private_competition_league,
        num_of_entrants: private_competition_entrants,
        payout_1st: "0",
        payout_2nd: "0",
        payout_3rd: "0",
        payout_club: "0",
        sport_id: private_competition_sport,
      };
      var headers = {
        session_key: session_key,
      };

      showLoader();
      axios
        .post(create_private_competition_url, body, { headers })
        .then((response) => {
          if (response.status === 200) {
            setSuccesStatus(true);
            alert_notify(
              "success",
              "Private competition request has been made successfully."
            );
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
    } else {
      alert_notify("error", "No League selected");
    }
  };

  const getCreatePrivateCompetitionsLabels = () => {
    var cp_labels_url = "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "create private competition",
    };
    showLoader();
    axios
      .post(cp_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setCpLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  useEffect(() => {
    getCreatePrivateCompetitionStaticContent();
    var session_key = localStorage.getItem("session_key");
    var view_all_sport_url = `${url}/api/common/sidebar`;

    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(view_all_sport_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          data.sort((a, b) => (a.sport_name > b.sport_name ? 1 : -1));
          console.log(data);
          setSports(data);
          var leagues_array = [];
          getCreatePrivateCompetitionsLabels();
          for (var i = 0; i < data.length; i++) {
            var row = data[i];
            var states = row.leagues;
            if (states != null) {
              for (const property in states) {
                for (var k = 0; k < states[property].length; k++) {
                  leagues_array.push(states[property][k]);
                  setLeagues(leagues_array);
                  setLoading(false);
                }
              }
            }
          }
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err);
        navigate("/login");
        localStorage.removeItem("session_key");
      });
    setSuccesStatus(false);
  }, [leagueId]);

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !cpLabels.create_private_competition_page_label
            ? "CREATE PRIVATE COMPETITION"
            : cpLabels.create_private_competition_page_label.label_name
        }
      />
      <div className="create_private_competition_container primary-background">
        <div className="page_content_center">
          {successStatus ? (
            <SuccesNotification
              title=""
              content="Top Predictor private competition was created successfully. Check your email for the shareable link."
            />
          ) : (
            <div>
              <div className="row m-0">
                <div className="col-12">
                  <span className="private_competition_select_options text-white">
                    {cpStaticContent != null && cpStaticContent != undefined
                      ? Parser(cpStaticContent)
                      : "Please select one option only from each of the following:"}
                  </span>
                </div>
              </div>
              <form
                id="form_private_competition"
                onSubmit={submitCreatePrivateCompetition}
              >
                <div className="row private_competition_sport_league">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="private_competition_select"
                      id="input_private_competition_sport"
                      onChange={handleSportChange}
                      value={selectSportid}
                    >
                      <option value="0">
                        {!cpLabels.create_private_competition_sport_label
                          ? "Sport"
                          : cpLabels.create_private_competition_sport_label
                              .label_name}
                      </option>
                      {sports.map((sport, index) => {
                        if (sport.leagues) {
                          console.log(sport);
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                            >
                              {sport.sport_name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="private_competition_select"
                      id="input_private_competition_league"
                      onChange={(event) => {
                        setSelectLeagueId(event.target.value);
                      }}
                      value={selectLeagueid}
                    >
                      <option value="0">
                        {!cpLabels.create_private_competition_league_label
                          ? "League"
                          : cpLabels.create_private_competition_league_label
                              .label_name}
                      </option>
                      {leagues.map((league, index) => {
                        if (league.sport_id == sportId) {
                          return (
                            <option
                              data-id={league.league_id}
                              data-value={league.league_id}
                              key={index}
                              value={league.league_id}
                            >
                              {league.league_name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>
                <div className="row private_competition_entry_fee_entrants">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="number"
                      id="input_private_competition_entry_fee"
                      className="private_competition_select"
                      placeholder={
                        !cpLabels.create_private_competition_entryfee_label
                          ? "Entry Fee (EUR)"
                          : cpLabels.create_private_competition_entryfee_label
                              .label_name + " (EUR)"
                      }
                      value={entryFee}
                      data-type="entryfee"
                      min="0"
                      onChange={(e) => {
                        return setEntryFee(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="number"
                      id="input_private_competition_entrants"
                      className="private_competition_select"
                      placeholder={
                        !cpLabels.create_private_competition_entrants_label
                          ? "Entrants"
                          : cpLabels.create_private_competition_entrants_label
                              .label_name
                      }
                      value={entrants}
                      data-type="entrants"
                      min="2"
                      onChange={(e) => {
                        return setEntrants(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0 mt-5">
                  <div className="col-12 create_private_competition_btn_container">
                    <ButtonPrimary
                      textValue={
                        !cpLabels.create_private_competition_create_label
                          ? "Create"
                          : cpLabels.create_private_competition_create_label
                              .label_name
                      }
                      classValue="btn_primary btn_create_private_competition"
                      datapath="my_competition"
                      type="submit"
                      form="form_private_competition"
                      onClick={getPrivateCompetitionData}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
